$table-background-color: rgba(5, 183, 228, 0.1882352941);
$table-background-white-color: #ffffff;
$table-border-color: #222;
$table-bottom-border-color: rgb(148, 148, 148);
$table-top-border-color: #000;
$table-head-background-color: #d0f6ff;

.lens_table_background_color_primary {
  background-color: $table-background-color;
}

.lens_table_background_color_secondary {
  background-color: $table-background-white-color;
}

.lens_table_hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.border-right {
  border-right: 1px solid $table-border-color !important;
}

.lens_table_border-top {
  border-bottom: 1px solid $table-border-color !important;
}

.lens_table_first_column {
  background-color: $table-background-white-color;
  text-align: left;
  vertical-align: top;
  padding-top: 1rem;
  min-width: 150px;
}

.lens_table_custom_table {
  max-height: 60vh;

  .table-thead {
    z-index: 4 !important;

    th {
      font-size: 14px;
      font-weight: 500;
      padding: 15px;
      background: $table-head-background-color;
    }
  }

  .table-tbody {
    border-top: 1px solid $table-top-border-color;

    td {
      padding: 15px 30px;
      border-bottom: 1px solid $table-bottom-border-color;
      font-size: 12px;
    }

    .border-right {
      border-right: 1px solid $table-border-color !important;
    }

    .border-left {
      border-left: 1px solid $table-border-color !important;
    }
  }
}

@media screen and (min-height: 1441px) {
  .lens_table_custom_table {
    max-height: 75vh;
  }
}
