.new-custome-search {
    border: 1px solid #505D6F50;
    border-radius: 4px;
    // padding: 12px;
    background: #FFF;
    min-height: 55px;
    width: 100%;
    padding-left: 40px;
    // overflow-wrap:break-word;
}

.new-custome-search:focus {
    outline: none;
}

.add-product-btn{
    border-radius: 4px;
    background: #05B7E4;
    color: #fff;
    padding: 8px 20px;
    right: 1%;
    top: 15%;

}