.w-12 {
    width: 12%;
}

.add-image {
    // position: absolute;
    // top: 267px;
    // left: 392px;
    // z-index: 1;
    cursor: pointer;
    align-self: end;
    margin-left: -2.2rem;
}

.custom_table {
    height: 70vh;

    .table-thead {
        z-index: 4 !important;

        th {
            font-size: 14px;
            font-weight: 500;
            padding: 15px;
            background: #D0F6FF;
        }
    }

    .table-tbody {
        border-top: 1px solid #000;

        td {
            padding: 15px 30px;
            border-bottom: 1px solid rgb(148, 148, 148);
            font-size: 12px;
        }

        .border-right {
            border-right: 1px solid #222 !important;
        }

        .border-left {
            border-left: 1px solid #222 !important;
        }
    }

    .background-light-blue {
        background-color: #6bdefb30;
    }

    .background-blue {
        background-color: #05b7e430;
    }


}

.pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagination-buttons {
    border: none;
}

.pagination-buttons button {
    // padding: 5px 10px;
    // margin-right: 15px;
    background-color: transparent;
    border: none;
    // border: 1px solid #ccc;
    // border-radius: 4px;
    cursor: pointer;
}

.pagination-buttons button.active {
    background: #05B7E4;
    color: #fff !important;
    border: none;
    padding: 10px 20px;
}


.brand-profile {
    width: 7.5rem;
    height: 7.5rem;
    border: 1px solid gray;
    border-radius: 50%;
    overflow: hidden;
}

.div-fit-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.brand-add-Button {
    width: 31px;
    height: 31px;
    border: 1px solid;
    border-radius: 26px;
    color: white;
    background: #05B7E4;
    box-shadow: 0px 5px 7px 3px #D9D9D9;
    border: none;
}

.dashed-border {
    border: 1px solid gray;
    border-style: dashed;
}

.item {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.product-component {
    display: flex;
    // justify-content: center;
    align-items: center;

    .p-brand-col {
        width: 18.5%
    }

    .delete-btn {
        margin-top: 25px;
        padding-top: 10px;
        width: 7%;
        height: 55px;
        border: 1px solid #FB3F3F;
        border-radius: 5px;
        text-align: center;
        align-items: center;
    }
}

.disabled-add-Button {
    width: 31px;
    height: 31px;
    border: 1px solid;
    border-radius: 26px;
    color: white;
    background: gray;
}

.brandImage {
    display: flex;
    word-break: break-all;
}

.img {
    width: 42px !important;
    height: 42px !important;
    border: 1px;
}

.img-cont {
    width: 42px !important;
    height: 42px !important;
}

.brandName {
    margin-left: 10px;
    font-size: 14px;
    word-break: break-all;
}


.add-image-text-error {
    align-self: end;
    position: relative;
    top: 1.3rem;
    right: 7rem;
}