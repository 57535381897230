.container {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.barcode-container {

    .title {
        color: black;
        font-family: Calibri, sans-serif;
        font-style: normal;
        text-decoration: none;
        font-size: 8px;
        text-align: left;
        line-height: 10px;
    }

    .price {
        color: black;
        font-family: Calibri, sans-serif;
        font-style: normal;
        text-decoration: none;
        font-size: 10.5px;
        text-align: left;
        line-height: 11px;
    }

    .product_id {
        color: black;
        font-family: Calibri, sans-serif;
        font-style: normal;
        text-decoration: none;
        font-size: 7.5px;
        text-align: left;
        line-height: 10px;
    }

    .brand {
        // padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
        font-weight: 700pt;
        font-size: 18px;

    }

    .product-barcode-img {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 100px;
    }
}

// ----------------------------------------------------
.diagonal-color-div {
    width: 20px;
    height: 20px;
    position: relative;
}

.half-color {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;

}

.half-color:nth-child(1) {
    clip-path: polygon(0 0, 100% 0, 0 100%);
}

.half-color:nth-child(2) {
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.color-div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.glass-div {
    border: 1px solid #222;
    border-radius: 4px;
    padding: 10px;
}