.list-title {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 20px;
}

.top-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 20px;
  overflow-y: scroll;
  padding: 10px;
}

.top-item-container {
  min-height: 80px;
  max-height: 150px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #f0f0f0;

}

.item {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  gap: 0.5rem;
  margin: 0;
}

.item button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.group {
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 20px;
  border: 1px solid #f0f0f0;
  max-height: calc(100vh - 400px);
  height: 100%;
  min-height: 200px;
  overflow: hidden;
}

.group-title {
  font-weight: bold;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.group-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

}

.group-scroll {
  overflow-y: scroll;
  max-height: calc(100vh - 500px);
  min-height: 100px;
  height: 100%;
}

.action-btn {
  background-color: #fff;
  border-radius: 10%;
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // transition: width 2s;
}

.action-btn:hover {
  width: 45px;
  height: 45px;
}

.button-bg-green:hover {
  background-color: #37d15980;
}

.button-bg-red:hover {
  background-color: #ff674680;
}

.icon-color-green {
  color: #37d15980;
}

.icon-color-red {
  color: #ff674680;
}

.green-border {
  border: 1px solid #37d15950;
}

.red-border {
  border: 1px solid #ff674650;
}

.group-container {
  position: relative;
  margin-bottom: 2rem;
}

.dropdown-container {
  max-width: 300px;
  margin: 0 auto;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: auto;
  cursor: pointer;

  .list-group-item {
    cursor: pointer;

    &:hover {
      background-color: #007bff;
      color: white;
    }
  }
}

.selected-values {
  .selected-item {
    background-color: #f0f0f0;
    color: #333;
  }
}

.form-control {
  border-radius: 0.25rem;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

@media (max-width: 576px) {
  .item {
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}