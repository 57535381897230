.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    // padding: 20px;
}

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label {
    margin-bottom: 5px;
    font-weight: bold;
}

.input {
    padding: 5px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
}

.page-container {
    position: relative;
    min-height: 200px;
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
}

.button-container-bottom {
    position: absolute;
    bottom: 20px;
    right: 20px;
}