.popup-main {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgb(0, 0, 0, 0.7);
}

.popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.popup-image {
    max-width: 90%;
    max-height: 90%;
}

.left-icon{
    position: fixed;
    top: 45%;
    right: 95%;
    font-size: xx-large;
}

.right-icon{
    position: fixed;
    top: 45%;
    left: 95%;
    font-size: xx-large;
}

.image-height{
    height: 70vh;
    width: 70vw;
    overflow: hidden;
}