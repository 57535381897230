.custom-search {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin-top: 0.25rem;
    border: 1px solid #505D6F;
    padding: 0;
    margin-top: -3px;
    border-top-right-radius: 0;
    border-top-left-radius: 0; 

    li {
        padding: 1rem 0 1rem 2rem;
        // border-bottom-right-radius: 8px;
        // border-bottom-left-radius: 8px;
    }
}

.searchClass{
    height: 50px;
}
