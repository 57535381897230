.wrapper-layout {
    display: flex;
    width: 100%;
}

.wrapper-content {
    display: flex;
    flex: 1;
    margin-left: 15em;
    margin-top: 5em;
    overflow:hidden;
}

.wrapper-card {
    padding: 25px;
    margin: 30px;
    width: 100%;
    // min-height: 80vh;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFF;
    overflow-x: inherit;
}

// @media (min-width: 1337px) and (max-width:1440px) {
//     .wrapper-card {
//         min-height: 82vh;
//     }   
// }

@media (min-width: 1287px) and (max-width:1336px) {
    .wrapper-card {
        min-height: 82vh;
    }   
}
@media (min-width: 1285px) and (max-width:1286px) {
    .wrapper-card {
        min-height: 85vh;
    }   
}
@media (min-width: 1275px) and (max-width:1284px) {
    .wrapper-card {
        min-height: 83vh;
    }   
}
@media (min-width: 1201px) and (max-width:1274px) {
    .wrapper-card {
        min-height: 82vh;
    }
    
}

@media (min-width: 566px) and (max-width:1024px) {
    .wrapper-content {
        margin-left: 0em;
    }
}

@media (min-width: 360px) and (max-width:565px) {
    .wrapper-content {
        margin-left: 0em;
    }
}