.multi_select {
    // z-index: 3;
    min-height: 50px !important;
    border-radius: 4px;
    background: #FFF;

    .css-13cymwt-control {
        min-height: 55px;
        // .css-3w2yfm-ValueContainer {
        //     height: 40px;
        //     overflow: scroll;
        // }
    }

    .css-t3ipsp-control {
        min-height: 55px;
    }

    .css-1u9des2-indicatorSeparator {
        background-color: transparent !important;
    }
}

.product_multi_select {

    border-color:black;
    .css-13cymwt-control {
        min-height: 45px;
    }

    .css-t3ipsp-control {
        min-height: 45px;
    }

}

.select-min-height{
    .css-13cymwt-control {
        min-height: 48px !important;
    }

    .css-t3ipsp-control {
        min-height: 48px !important;
    }
}

// .react-select__menu {
//     z-index: 10;
// }

