.column-style{
    .rdt_Table{
        // overflow-x: scroll;
        .rdt_TableHead{
            .rdt_TableHeadRow{
                // max-width: 100%;
                .rdt_TableCol:first-child{
                    min-width: 70%;                    
                }
                .rdt_TableCol:last-child{
                    min-width: 30%;                    
                }
                @media (min-width:'360px') and (max-width:'1140px') {
    
                    .rdt_TableCol:first-child{
                        min-width: auto;                    
                    }
                    .rdt_TableCol:last-child{
                        min-width: auto;                    
                    }
            }

        }
    }
        .rdt_TableBody{
            .rdt_TableRow{
                // max-width: 100%;

                .rdt_TableCell:first-child{
                    min-width: 70%;                    
                }
                .rdt_TableCell:last-child{
                    min-width: 30%;                    
                }

                @media (min-width:'360px') and (max-width:'1140px') {

                    .rdt_TableCell:first-child{
                        min-width: auto;                    
                    }
                    .rdt_TableCell:last-child{
                        min-width: auto;                    
                    }
                }
            }
    }
}
}

.rdt_TableRow{
    min-height: 50px !important;
}