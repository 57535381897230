.search-btn {
  padding: 7px 30px;
  background: #05b7e4;
  border: 0;
  border-radius: 4px;
  color: #fff;
  top: 15%;
  right: 1%;
}
.checkout-card {
  border: 1px solid #505d6f50;
  border-radius: 4px;
  padding: 15px;

  .checkout-product-card {
    border: 1px solid #505d6f50;
    border-radius: 4px;
    padding: 15px;
  }
  .checkbox-style {
    width: 24px;
    height: 24px;
    border-radius: 4px !important;
  }
  .product-image {
    width: 100%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.roundoff-inp {
  max-width: 80px;
  // max-width: 100px;
  border: 1px solid #505d6f;
  border-radius: 4px;
  height: 40px;
  padding:0 10px 0 10px;
}
.proceed-btn {
  width: 100;
  min-height: 45px;
  border-radius: 4px;
  border: none;
  background: #05B7E4;
  /* Button Shadow 1 */
  box-shadow: 0px 4px 4px 0px #505D6F25;
}
.sales-custome-select{
  min-width: 200px;
  cursor: pointer;
}