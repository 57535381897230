.rdt_TableRow {
  background: none !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 500;

  .rdt_TableCell div {
    white-space: normal !important
  }
}

// .rdt_TableRow:nth-child(even) {
//   background: #d0f6ff73 !important;
//   // background: rgba(208, 246, 255, 0.30) !important;;
// }

.rd-row-accordion {
  display: none;
  /* Hide the expand button */
}

/* Add these styles to your CSS file */
.custom-data-table-container {
  position: relative;
  min-width: 100% !important;
  width: 100% !important;

}

.custom-data-table-container .rdt_TableBody {
  // max-height: 350px;
  overflow-y: auto;
  // overflow-x: auto;
}

.custom-data-table-container .rdt_TableHead,
.rdt_TableHeadRow {
  position: sticky;
  top: 100;
  background-color: #D0F6FF;
  z-index: 1;
}

// ul.dropdown-menu {
//   min-width: auto !important;
// }

.rdt_TableCol_Sortable div {
  white-space: normal !important;
}


// Reduce scroll width
.custom-data-table-container .rdt_TableBody::-webkit-scrollbar {
  width: 8px;
}

.custom-data-table-container .rdt_TableBody::-webkit-scrollbar-thumb {
  background-color: #888;
}

.custom-data-table-container .rdt_TableBody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

@media only screen and (max-height:594px) {
  .custom-data-table-container .rdt_TableBody {
    max-height: 350px;
  }

  .custom-data-table-container {
    overflow-x: hidden !important;
  }
}

@media screen and (max-height: 1440px) {
  .custom-data-table-container .rdt_TableBody {
    max-height: 45vh;
  }

  .data-table-container {
    overflow-x: hidden !important;
  }
}


@media screen and (min-height: 1441px) {
  .custom-data-table-container .rdt_TableBody {
    max-height: 80vh;
  }
}

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.custom-data-table-container .rdt_TableBody {
  min-height: 15vh;
}

// .errorRow .rdt_TableBody, .rdt_TableRow {
//   background-color: red;
// }