input[type="number"] {
    /* Remove arrows in modern browsers */
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}

/* Hide arrows in Internet Explorer */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-ms-clear {
    display: none;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
}

::placeholder {
    /* Recent browsers */
    text-transform: none;
}