.custom-color-input {
    min-height: 55px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    align-items: center;
    display: flex;

    input {
        width: 15%;
        padding: 0;
        margin-left: 5%;
        box-shadow: none;
    }
}