.inputs-label {
    font-family: 'Inter';

}

.inputs {
    min-height: 55px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    // pointer-events: none
}

.input-height {
    min-height: 45px !important;
}

.light-placeholder::-webkit-datetime-edit { /* Chrome, Safari, Opera */
    color: #556874;
}

// .light-placeholder::-moz-placeholder { /* Firefox 19+ */
//     color: #556874;
//     opacity: 1; /* required for Firefox */
// }