.add-btn{
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px 20px;
    background : #05B7E4;
    color: #fff;
}

.disable-btn{
    opacity: 0.5 !important;
}

.button-transparent{
    background: #fff;
    color: #05B7E4;
    border-color: #05B7E4 !important;
    // border: 2px solid;
}