.rdt_TableRow {
  background: none !important;
  border: none !important;
  font-size: 12px !important;
  font-weight: 500;

  .rdt_TableCell div {
    white-space: normal !important
  }

  .rdt_TableCell:first-child {
    // justify-content: center;    
    padding-left: 30px;

  }
}

.rdt_TableHeadRow {
  .rdt_TableCol:first-child {
    // justify-content: center;   
    padding-left: 30px;
  }
}

.rdt_TableRow:nth-child(even) {
  background: #d0f6ff73 !important;

  // background: rgba(208, 246, 255, 0.30) !important;;
}

.rd-row-accordion {
  display: none;
  /* Hide the expand button */
}

/* Add these styles to your CSS file */
.data-table-container {
  position: relative;
  min-width: 100% !important;
  width: 100% !important;

}

.data-table-container .rdt_TableBody {
  // max-height: 350px;
  overflow-y: auto;
  // overflow-x: auto;
}

.data-table-container .rdt_TableHead,
.rdt_TableHeadRow {
  position: sticky;
  top: 100;
  background-color: #D0F6FF;
  z-index: 1;
}

// ul.dropdown-menu {
//   min-width: auto !important;
// }

.add-invertory-table .rdt_TableBody {
  min-height: 35vh !important;
}

.add-invertory-tables .rdt_TableBody {
  min-height: 35vh !important;

  .rdt_TableRow {
    min-height: 105px !important;
  }
}

.rdt_TableCol_Sortable div {
  white-space: normal !important;
}

// Reduce scroll width
.data-table-container .rdt_TableBody::-webkit-scrollbar {
  width: 8px;
}

.data-table-container .rdt_TableBody::-webkit-scrollbar-thumb {
  background-color: #888;
}

.data-table-container .rdt_TableBody::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

@media only screen and (max-height:594px) {
  .data-table-container .rdt_TableBody {
    max-height: 350px;
  }

  .data-table-container {
    overflow-x: hidden !important;
  }
}

@media screen and (max-height: 1440px) {
  .data-table-container .rdt_TableBody {
    max-height: 49vh;
  }

  .data-table-container {
    overflow-x: hidden !important;
  }
}

@media (min-height: 1441px) and (max-height:2560px){
  .data-table-container .rdt_TableBody {
    max-height: 75vh !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-height:2560px){
  .data-table-container .rdt_TableBody {
    max-height: 80vh !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.data-table-container .rdt_TableBody {
  min-height: 49vh;
}

.adjustable-row-height .rdt_TableBody {

  .rdt_TableRow {
    min-height: auto !important;
    padding:10px
  }
}