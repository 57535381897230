.container{
    display: flex;
    flex-direction: column;
}
.content {
    max-height: 400px;
    overflow-y: scroll;
    
  }
  .hide-scroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .table-tbody{
    
    tr:nth-child(even) {
      background-color: rgba(5, 183, 228, 0.1882352941);
   }
  }

 .custom-height{
  height: auto !important ;
 }

 .custom-delete-button {
  text-align: center;
  border-radius: 4px;
  border: 1px solid #FB3F3F;
  background-color: transparent;
  padding: 7px 12px;
  color: #FB3F3F;

}
 