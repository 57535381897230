.upload_main {

    .upload_container {
        max-width: 164px;
        margin-top: "14px";
    }

    .upload_image_wrapper {
        position: relative;

        // max-width: 100%;
        .upload_image_container {
            width: 165px;
            height: 140px;
            position: relative;
            border-radius: 5px;
            border: 1.5px dashed rgba(80, 93, 111, 0.40);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

            .upload_btn {
                width: 94px;
                height: 28.678px;
                border-radius: 4px;
                background: #D9D9D9;
                color: #505D6F;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }

            p {
                color: #505D6F;
                text-align: center;
                line-height: 150%;
            }
        }
    }

    .photo_upload {
        width: 165px;
        height: 140px;
        // margin: 1rem 0rem 0rem 1rem !important;
        position: relative;

        .photo_preview {
            width: 165px;
            height: 140px;
            position: relative;
            border-radius: 4px;
            border: 1.8px solid rgba(80, 93, 111, 0.50);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

            .preview_image {
                width: 100%;
                height: 100%;
                position: absolute;
                background-size: cover;
                border-radius: 4px;
            }

        }

        .delete-icon {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 20px;
            height: 20px;
            border-radius: 0px 4px;
            background: #D9D9D9;
            cursor: pointer;

            &:hover {
                background: #e1d4cd;

            }

        }
    }
}