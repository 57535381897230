.translate{
    transform: translate(10%,-0%);
  }

.translate-absolute{
    transform: translate(200%,-0%);
}

.translate-50{
    transform: translate(-50%,-0%);
  }

  .badges-style{
    font-size: 10px;
  }