.checkbox-dropdown {
    padding: 1rem;

    .checkbox-option {
        color: #505D6F;
        font-size: 18px;
        font-weight: 400;
    }

    .children-container {
        padding-left: 1rem;

    }
}

.permission-checkbox{
    label{
        color: #505D6F;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    input[type=checkbox] {
        accent-color:#05B7E4;
        color: #ffffff !important;
        color: #505D6F;
        width: 20px ;
        height: 20px ;
        cursor: pointer;
      }


}