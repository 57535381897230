*{
    font-family: 'inter' !important;
}

/* second stepper Started */
  .stepper-wrapper {
    // width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .stepper-wrapper::before {
    content: "";
    width: 85%;
    height: 8px;
    background-color: lightgray;
    position: absolute;
    z-index: -2;
    left: 8%;
    top: 32%;
  }

  .progress-stepper {
    content: "";
    width: 0%;
    height: 8px;
    background-color: #f4178f;
    position: absolute;
    z-index: -1;
    transition: width 1s;
    left: 8%;
    top: 32%;
  }

  .screen-indicator {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid lightgrey;
    background-color: white;
    padding: 10px 13px;
    color: gray;
    transition: border-color color;
    transition-duration: 0.7s;
  }
  .completed {
    border-color: #f4178f;
    color: #f4178f;
  }

  /* Stepper active section  */
  .cureent-active{
    color: #f4178f !important;
  }
  .step{
    color: #3E353F;
    width: 53px;
    height: 53px;
  }
  .step.completed{
    color: #fff !important;
    width: 53px;
    background-color: #f4178f;
    height: 53px;
  }
  .stepper-inactive{
    display: block;
  }
  .stepper-active{
    display: none;
  }
  .step.completed .stepper-active{
    display: block;
  }
  .step.completed .stepper-inactive{
    display: none;
  }
  .fs-stepper{
    color: #c2c2c2;
    font-size: 14px;
    font-weight: 700;
    width: 200px;
    white-space: nowrap;
  }
//   .fs-stepper.active{
//     color: #f4178f;
//   }


  /* mobile progressbar started */
  .mobile-progress-subheading{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #05B7E4;  
  }
  .mobile-progress-heading{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #2D3748;
  }
  .bar-height{
    height: 6px !important;
    background: #05B7E4 !important;
  }
  .progress-new-color{
    height: 6px !important ;
    background: #E2E8F0 !important;
  }

  /* second page stepper started */

  @charset "UTF-8";
  .multi-steps > li.is-active ~ li:before, .multi-steps > li.is-active:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  .multi-steps > li.is-active ~ li:after, .multi-steps > li.is-active:after {
    background-color: #ededed !important;
    height: 3px;
  }

  .multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #05B7E4;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
  .multi-steps > li:before {
    content: "";
    content: "✓;";
    content: "𐀃";
    content: "𐀄";
    content: "✓";
    display: block;
    margin: 0 auto 4px;
    color: #05B7E4;
    background-color: #fff;
    border: 4px solid #05B7E4;
    margin-bottom: 15px;
    width: 43px !important;
    height: 43px !important;
    line-height: 45px;
    text-align: center;
    font-weight: bold;
    border-radius: 50%;
    position:inherit;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .multi-steps > li:after {
    content: "";
    height: 3px;
    width: 94.5%;
    background-color: #05B7E4;
    position: absolute;
    top: 22px;
    left: 50%;
  }
  .multi-steps > li:last-child:after {
    display: none;
  }
  .multi-steps > li.is-active:before {
    background-color: #05b7e4;
    // border: 4px solid #05b7e4;
    color: #05B7E4;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px !important;
    height: 30px !important;
    z-index: 1;
    position: inherit;
    outline-offset: 8px!important;
    outline-style: auto!important;
    outline-width: thick!important;
    margin-bottom: 20px;
  }
  .multi-steps > li.is-active ~ li {
    color: #808080;
  }
  .multi-steps > li.is-active ~ li:before {
    background-color:#fff ;
    border:3px solid #718096;
    color: #718096;
    line-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px !important;
    height: 43px !important;
    margin-bottom: 15px;
    position: inherit;

  }

  /* closed stepper CSS section*/


  /* mobile stepper number started */
  .mobile-stepper-no{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color: #05B7E4;  
    transition-duration: 0.7s;
  }


  /* media query started */
  @media (min-width: 320px) and (max-width:767px) { 

    .w-97{
      width: 97%;
    }
    .card-heading{
      font-size: 28px ;
    }
    .space-2{
      margin-bottom: 197px !important;
    }
    .space-3{
      margin: 155px 0px 0px 0px !important;
    }  
    .btn-margin{
      margin: 30px 0px 0px 0px !important;
    }
    .stepper-box-bg{
      min-height: 175px !important;
    }
    .mobile-w-100{
      width: 100% !important;
    }
    .progress-w-100{
      width: 85%;
    }
  }


  @media (min-width: 768px) and (max-width:1199px) {
    .stepper-wrapper::before {   
      width: 75%;
      left: 12%;
  }

  }

// /* Stepper.css */
// .stepper {
//   border: 1px solid #ccc;
//   padding: 16px;
//   border-radius: 4px;
// }

// .step-indicators {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 16px;
// }

// .step-indicator {
//   width: 24px;
//   height: 24px;
//   border: 1px solid #ccc;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .step-indicator.active {
//   background-color: #007bff;
//   color: #fff;
// }

// .step-content {
//   margin-bottom: 16px;
// }

.step-buttons button {
  // margin-right: 8px;
}