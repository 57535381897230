#root {
    background: #fafafa;
    height: 100vh;
}

.main-color {
    color: #05B7E4;
}

.secondary-color {
    color: #556874;
}

.black-color {
    color: #243641;
}

.color-black {
    color: #000
}

.color-gray {
    color: gray
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-26 {
    font-size: 26px;
}

.fs-30 {
    font-size: 30px;
}


.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.button-background {
    background-color: #05B7E4;
}

.color-white {
    color: rgb(255, 255, 255);
}

.color-green {
    color: #138200
}

.color-yellow {
    color: #FFBA00
}

.color-red {
    color: #FB3F3F
}

.background-red {
    background-color: #FB3F3F;
}

.background-light-green {
    background-color: #D1FFCA;
}

.background-light-yellow {
    background-color: #FFF6E0;
}

.background-light-blue {
    background-color: #4bc4e265;
}

.color-blue {
    color: #002f68;
}

.table-delete-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #FB3F3F;
    background-color: transparent;
    padding: 7px 20px;
    color: #FB3F3F;

}

.table-success-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #138200;
    ;
    background-color: transparent;
    padding: 7px 28px;
    color: #138200;
    width: 95px;

}

.table-edit-button {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #505D6F;
    background-color: transparent;
    padding: 7px 20px;
    color: #505D6F;

}

.table-edit-buttons {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #05B7E4;
    background-color: transparent;
    padding: 7px 20px;
    color: #05B7E4
}

.background-light-red {
    background-color: #FFD1D1;
}

.background-secondary {
    background-color: rgb(148 148 148 / 40%);
    border-radius: 3px;
}

.border-product-radius {
    border-radius: 5px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.h-95{
    height: 95% !important;
}


.add-product-custom-div {
    border: 1px solid #80808078;
    min-width: 13rem;
}

.photo_upload {
    width: 165px;
    height: 140px;
    // margin: 1rem 2rem 1rem 0 !important;
    position: relative;

    .photo_preview {
        width: 165px;
        height: 140px;
        position: relative;
        border-radius: 4px;
        border: 1.8px solid rgba(80, 93, 111, 0.50);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        .preview_image {
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover;
            border-radius: 4px;
        }
    }

    .delete-icon {
        position: absolute;
        right: -10px;
        top: 2px;
        width: 20px;
        height: 20px;
        border-radius: 0px 4px;
        background: #D9D9D9;
        cursor: pointer;

        &:hover {
            background: #e1d4cd;

        }

    }
}


.cursor-pointer {
    cursor: pointer;
}

.id-input-design {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 10px;
}

.dark-gray {
    color: #505d6f;
}

.Delete-Button {
    background: white;
    color: #ff0500cc;
    border: 1px solid #ff000082;
}

.mini-stats-wid {
    .mini-stat-icon {
        overflow: hidden;
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 8px;
            height: 54px;
            background-color: #fff;
            left: 16px;
            transform: rotate(32deg);
            top: -5px;
            transition: all 0.4s;
        }

        &::after {
            left: -12px;
            width: 12px;
            transition: all 0.2s;
        }
    }

    &:hover {
        .mini-stat-icon {
            &::after {
                left: 60px;
            }
        }
    }
}

.dashboard-card-height {
    // height: 157px;
    background: #fff;
    color: #556ee6;
    border-radius: 21px;
    box-shadow: 0px 0px 5px #00000030
}

.login-height {
    max-height: 46rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    border-radius: 20px 0px 0px 20px !important;
    min-height: 100%;
}

.color-ghostwhite {
    color: ghostwhite;
}

.error {
    border: 1px solid rgb(255, 48, 48);
}

.error-text {
    color: rgb(255, 48, 48);
    font-size: 12px;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.list-hover:hover {
    background-color: #deebff
}

.custom-input-height {
    min-height: 35px !important;
}

.action-button {
    background: #D0F6FF;
    ;
    border: aliceblue;
}

.action-button:hover {
    background-color: gray !important;
    border: aliceblue;
}

.action-button:active {
    background-color: gray !important;
    border: aliceblue;
}

.object-contain {
    object-fit: contain;
}

.cover-image {
    background: linear-gradient(#46424270, #504d4d);

    .cover-text {
        color: #fff
    }
}

.border-tb {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.zIndex-3 {
    z-index: 3;
}

.inventory-product-border {
    overflow: auto;

    .root {
        .rdt_TableRow:nth-child(even) {
            background: #FFF;
            border-top: 2px solid #000;
            border-bottom: 2px solid #000;
        }
    }

}

.inventory-product-border {
    overflow: auto;

    ::-webkit-scrollbar {
        display: unset;
        max-height: 10px !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #808080;
        /* Change color as needed */
    }
}

.ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.inventory-quantity-table-height {
    height: 58vh;
}


@media screen and (min-height: 1441px) {
    .inventory-quantity-table-height {
        height: 85vh;
    }
}


.custom-tabs {
    .nav-tabs {
        border-bottom: 1.5px solid #80808078;

        .nav-item {

            display: flex;
            justify-content: center;
            width: 30%;
            font-weight: 600;

            .nav-link {
                color: #7c7c7c;
                background: transparent;
                border: 0;
                // border-bottom: 3px solid #80808078;
            }
        }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        border-bottom: 3px solid #05b7e4;
        width: 100%;
        // color: #05b7e4;
    }
}

.border-dark-gray{
    border-color: rgba(80,93,111,.314) !important;
}
.color-gray{
    color:gray
}

.hide-barcode-price{
    visibility: hidden;
}

.show-barcode-price{
    visibility: visible;
}