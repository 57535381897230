@media print {

    /* === old size || keep this too ==== */

    /* @page {
        size: 100mm 230mm;
    } */

    /* === old size || keep this too ==== */
    @page {
        size: 100mm 18mm;
    }

    .page-break {
        page-break-after: always;
    }

}